import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API HRPositions */
export function getHRPositions(listQuery) {

  // console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/hrpositions/m/search?search=' + search + '&_limit=' + limit + '&_start=' + start
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListPositionsActive() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/hrpositions?isActive=true&_limit=1000'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getHRPositionsByID(id) {
  // console.log(id)
  if (id <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/hrpositions/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertHRPositions(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/hrpositions'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return {'id': 0, 'data':'', 'status': 'Data should be a unique please recheck the data.'}
  })
  // console.log(response)
  return response
}

export function deleteHRPositions(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/hrpositions/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateHRPositions(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/hrpositions/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countHRPositions(listQuery) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/hrpositions/m/count?search=' + search
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API HRPositions ==== */


