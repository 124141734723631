import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API HRUserPositions */
export function getUserPosRoles(token, userid) {
  // console.log('on api getUserPosRoles')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  if (userid <= 0) return null

  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/hruserpos/' + userid
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getHRUserPositions(listQuery) {

  // console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/f/search?search=' + search + '&_limit=' + limit + '&_start=' + start +'&_sort=id%3ADESC'
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListUsrPositionsActive() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions?isActive=true'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListUsrPosActiveAll() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpos/getall'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListUsrPositionsActiveNTStaff() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpos/getallexcludestaff'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getListUsrPositionsActiveByUnit(unit) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/f/byunit?unit=' + unit
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListUserPosByPosition(userpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/findPosByPosition/' + userpos
  var data = {hruserpositions: userpos}
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getListUsrPositionsActiveByCurrPos(curpos) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/byCPOS/' + curpos
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getHRUserPositionsByID(id) {
  // console.log(id)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (id <= 0) return null

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertHRUserPositions(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var dheaders = {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': `Bearer ${token}`
  }
  // console.log(dheaders)
  var uri = _GLOBAL.URLBACKEND + '/hruserpositions'
  var response = request({
    url: uri,
    method: 'post',
    headers: dheaders,
    data
  }).catch((err) => {
    return {'id': 0, 'data':'', 'status': 'Data should be a unique please recheck the data.'}
  })
  // console.log(response)
  return response
}

export function deleteHRUserPositions(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateHRUserPositions(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countHRUserPositions(listQuery) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/hruserpositions/f/count?search=' + search
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API HRUserPositions ==== */


